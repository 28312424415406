<template>
    <section class="page-404 container-fluid flex-center bg-black">
        <div class="text-center">
            <span class="lm--title-lg montserrat-black text-primary"> 404 </span> <br>
            <span class="lm--title-md text-white"> Page Not Found </span>
        </div>
    </section>
</template>

<script setup>

</script>

<style lang="scss" scoped>
    .page-404 {
        height: 100vh;
    }
</style>
